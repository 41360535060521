import { TimeScale } from "./types";
import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

export const granularityTimeScaleLookup: Record<MhcTimeSeriesGranularityEnum, TimeScale> = {
  day: "daily",
  week: "weekly",
  month: "monthly",
  quarter: "quarterly",
  year: "yearly",
  five_year_window: "yearly"
};

export const tickInterval = (scale?: TimeScale) => {
  switch (scale) {
    case "daily":
      return 1000 * 3600 * 24;
    case "weekly":
      return 1000 * 3600 * 24 * 7;
    case "monthly":
      return 1000 * 3600 * 24 * 28;
    case "quarterly":
      return 1000 * 3600 * 24 * 30 * 3;
    case "yearly":
      return 1000 * 3600 * 24 * 365;
    default:
      return 1000 * 3600 * 24 * 2 * 12;
  }
};

export const dateTimeLabelFormats: Record<string, string> = {
  millisecond: "%H:%M:%S.%L",
  second: "%H:%M:%S",
  minute: "%H:%M",
  hour: "%H:%M",
  day: "%m/%e",
  week: "%m/%y",
  month: "%b %Y",
  year: "'%y"
};

// const colors = colorConfig.set;
export const axisConfig = {
  gridLineWidth: 1,
  lineWidth: 1,
  lineColor: "#000000",
  tickColor: "#000000",
  tickWidth: 1,
  tickLength: 5
};
export const barColumnChartPlotOpts = {
  maxPointWidth: 200,
  borderRadius: 2,
  opacity: 0.8,
  borderWidth: 1
};
