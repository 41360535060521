import { Box } from "@mui/material";

import { defaultChartColorSet } from "../util/color/config";

const ChartSeriesIcon: React.FC<{ color?: string; colorIndex?: number }> = ({
  color,
  colorIndex
}) => {
  const _color = color ?? defaultChartColorSet[colorIndex ?? -1] ?? "#636363";
  return (
    <Box
      sx={{
        flex: "0 0 12px",
        background: _color,
        height: "12px",
        width: "12px",
        borderRadius: "50%"
      }}
    />
  );
};

export default ChartSeriesIcon;
