import last from "lodash/last";
import sortBy from "lodash/sortBy";

import {
  DatasetGroup,
  DropdownOption,
  DropdownOptionIdentifierConfig
} from "common/components/charts/Investigate/types";
import {
  MhcStatIdentifierInGroupFragment,
  MhcStatIdentifierRelatedStratumGroupFragment,
  MhcStatIdentifierWithStratificationGroupCrossCategoriesFragment,
  MhcStatIdentifierWithStratificationGroupsFragment
} from "graphqlApi/types";
import { IdentifierConfig } from "modules/Topics/util/elementHelpers/dashboard/types";

import { FetchStratifiedDataForChart } from "@/DOD/@sudors/components/util";
import { capitalizeFirstLetter, titleize } from "common/util/helpers/string";

type OptionsFromSiStratificationGroups = Omit<
  FetchStratifiedDataForChart,
  "categories" | "alternateNameDictionary"
> & {
  baseIdentifierConfig?: Omit<IdentifierConfig, "identifier">;
  statIdentifiers: (
    | MhcStatIdentifierWithStratificationGroupsFragment
    | MhcStatIdentifierWithStratificationGroupCrossCategoriesFragment
  )[];
  sortGroups?: boolean;
  alternateNameDictionary?: FetchStratifiedDataForChart["alternateNameDictionary"];
};

type MhcStratificationGroups =
  MhcStatIdentifierWithStratificationGroupsFragment["stratificationGroups"];

type MhcStratificationGroupWithRelatedGroups = MhcStatIdentifierInGroupFragment &
  MhcStatIdentifierRelatedStratumGroupFragment;

type BasicStatIdentifier = { id: string; name: string };

export const OVERALL_NAME = "Overall";

export const optionsFromSiStratificationGroups = ({
  alternateNameDictionary = {},
  baseIdentifierConfig = {},
  granularities = [],
  overallIdentifier,
  omittedStratumGroups,
  optionOrderMap,
  removeOptionsWithoutData,
  sortGroups = true,
  statIdentifiers,
  stratumGroups
}: OptionsFromSiStratificationGroups) => {
  return statIdentifiers.map((unstratifiedSi, i): DatasetGroup => {
    const { id, name, fullName, stratificationGroups, ..._unstratifiedSi } = unstratifiedSi;
    const title = alternateNameDictionary[id] ?? name ?? fullName;
    const sortAndFilterGroup = (groups: MhcStratificationGroups): MhcStratificationGroups => {
      let _groups = groups;
      if (sortGroups) _groups = sortBy(groups, ({ name }) => name);
      // Filter to stratumGroups needed
      if (stratumGroups?.length) _groups = _groups.filter(({ id }) => stratumGroups.includes(id));
      // Filter stratum groups requested to be omitted
      if (omittedStratumGroups?.length)
        _groups = _groups.filter(({ id }) => !omittedStratumGroups.includes(id));
      return _groups;
    };

    const optionsFromGroup = (
      group: MhcStratificationGroups,
      options: { overallStatIdentifier?: BasicStatIdentifier } = {}
    ) => {
      const { overallStatIdentifier } = options;
      return group.reduce((options, { category, id, name, statIdentifiers }) => {
        const _statIdentifiers: BasicStatIdentifier[] = optionOrderMap
          ? sortBy(statIdentifiers, [
              ({ id, stratumGroupOrder }) => optionOrderMap[id] ?? stratumGroupOrder ?? 0
            ])
          : [...statIdentifiers];
        overallStatIdentifier && _statIdentifiers.push(overallStatIdentifier);
        options ||= {};
        const _option: DropdownOption = {
          category,
          id,
          title: capitalizeFirstLetter(
            (name?.includes("/") ? last(name.split("/")) : name) as string
          )
        };
        if (granularities?.length) {
          _option.options = _statIdentifiers.reduce((_options, { id, name }) => {
            _options ||= {};
            _options[name ?? id] = {
              id,
              title: name,
              category,
              options: granularities.reduce((granularityOptions, granularity) => {
                granularityOptions ||= {};
                granularityOptions[granularity] = {
                  id: granularity,
                  title: titleize(granularity),
                  identifierConfigs: [
                    {
                      identifier: id,
                      granularity,
                      ...baseIdentifierConfig
                    }
                  ]
                };
                return granularityOptions;
              }, {} as DatasetGroup["options"])
            };
            return _options;
          }, {} as DatasetGroup["options"]);
        } else {
          _option.identifierConfigs = _statIdentifiers.map(({ id }) => {
            let config: DropdownOptionIdentifierConfig = {
              identifier: id,
              ...baseIdentifierConfig
            };
            if (overallStatIdentifier) {
              const isOverall = id === overallStatIdentifier.id;
              config = {
                ...config,
                ...(isOverall ? overallIdentifier?.config ?? {} : {}),
                isOverall
              };
            }
            return config;
          });
        }
        options[name ?? id] = _option;
        return options;
      }, {} as DatasetGroup["options"]);
    };

    let options: DatasetGroup["options"];
    let optionGroups: DatasetGroup["optionGroups"] = [];
    const sortedGroups = sortAndFilterGroup(stratificationGroups as MhcStratificationGroups);
    if (
      stratificationGroups?.length > 1 &&
      stratificationGroups.every(({ statIdentifiers }) =>
        statIdentifiers.every((si) => "relatedStratumGroups" in si)
      )
    ) {
      optionGroups = sortedGroups.map(({ id, name, category, ...group }) => {
        let groupStatIdentifiers =
          group.statIdentifiers as MhcStratificationGroupWithRelatedGroups[];
        // Filter stat identifiers / options that probably don't have data based on
        // availableGranularities, availableYears, availableGeographies props
        if (removeOptionsWithoutData) {
          groupStatIdentifiers = groupStatIdentifiers.filter(
            ({
              availableGranularities,
              availableYears,
              availableGeographies,
              relatedStratumGroups
            }) => {
              let hasData =
                !!availableGranularities.length ||
                !!availableYears.length ||
                !!availableGeographies.length;
              if (relatedStratumGroups?.length) {
                hasData = relatedStratumGroups.some(({ statIdentifiers }) =>
                  statIdentifiers.some(
                    ({ availableGranularities, availableYears, availableGeographies }) =>
                      availableGranularities.length ||
                      availableYears.length ||
                      availableGeographies.length
                  )
                );
              }
              return hasData;
            }
          );
        }
        return {
          id,
          title: name,
          category,
          options: (groupStatIdentifiers as MhcStratificationGroupWithRelatedGroups[]).reduce(
            (options, { id, name, relatedStratumGroups = [] }) => {
              options ||= {};
              options[name ?? id] = {
                title: capitalizeFirstLetter(name),
                category,
                options: optionsFromGroup(
                  sortAndFilterGroup(relatedStratumGroups),
                  overallIdentifier?.include
                    ? {
                        overallStatIdentifier: {
                          id,
                          name: OVERALL_NAME
                        }
                      }
                    : undefined
                )
              };
              return options;
            },
            {} as DatasetGroup["options"]
          )
        };
      }) as DatasetGroup["optionGroups"];
    } else {
      options = optionsFromGroup(
        sortedGroups,
        overallIdentifier?.include
          ? { overallStatIdentifier: { id, name: OVERALL_NAME } }
          : undefined
      );
    }
    const datasetOption: DatasetGroup = {
      default: i === 0,
      groupTitle: title,
      statIdentifier: {
        fullName,
        id,
        name,
        ..._unstratifiedSi,
        stratificationGroups: []
      }
    };
    options && (datasetOption.options = options);
    optionGroups?.length && (datasetOption.optionGroups = optionGroups);

    return datasetOption;
  });
};
