import { ColorPaletteInterface } from "./types";

import { demographicColors } from "theme/colors";

export const NO_DATA_COLOR = "#636363";

export const colorFromUnitLabel = (unitLabel?: string | null): ColorPaletteInterface => {
  const toEvaluate = unitLabel?.toLowerCase();
  const { race = {}, ethnicity = {}, sex = {}, grade = {} } = demographicColors;
  switch (toEvaluate) {
    case "total":
      return { hex: "#aaa" };
    case "white":
      return { hex: race.white };
    case "black":
      return { hex: race.black };
    case "asian":
      return { hex: race.asian };
    case "multiple race":
    case "multiracial":
      return { hex: race.multiracial };
    case "other race":
      return { hex: race.otherRace };
    case "native american":
      return { hex: race.nativeAmerican };
    case "pacific islander":
    case "asian or pacific islander":
      return { hex: race.pacificIslander };
    case "non-hispanic":
      return { hex: ethnicity.nonHispanic };
    case "hispanic":
      return { hex: ethnicity.hispanic };
    case "female":
      return { hex: sex.female };
    case "male":
      return { hex: sex.male };
    case "patient declined to disclose":
      return { hex: "#EC829B" };
    case "grade 8":
      return { hex: grade.eight };
    case "grade 11":
      return { hex: grade.eleven };
    case "data not reported":
      return { hex: NO_DATA_COLOR, dashStyle: "ShortDashDotDot" };
    default:
      return { hex: undefined };
  }
};

interface BarColors {
  fill: string;
  border: string;
  text: string;
}

export const barColors: BarColors[] = [
  { fill: "rgb(229, 179, 3)", border: "#007AF5", text: "#70B8FF" },
  { fill: "rgb(30, 144, 255)", border: "#C99E03", text: "#FCD64A" }
];
