import { Stack, Typography } from "@mui/material";

import { MhcConfidenceInterval } from "graphqlApi/types";

import { TooltipFormatterParams, TooltipPoint } from "../util/tooltip";
import { formatValueByUnit } from "common/util/formatHelpers";

import ChartSeriesIcon from "./ChartSeriesIcon";
import ConfidenceIntervals from "./ConfidenceIntervals";

export interface ChartTooltipPointProps
  extends Pick<TooltipFormatterParams, "percent" | "precision" | "unit"> {
  point: TooltipPoint;
  confidenceIntervals?: MhcConfidenceInterval[][];
  single?: boolean;
  overrideSeriesName?: string;
  showPercentage?: boolean;
}

const ChartTooltipPoint: React.FC<ChartTooltipPointProps> = ({
  confidenceIntervals = [],
  overrideSeriesName,
  percent,
  point,
  precision,
  unit,
  showPercentage = false
}) => {
  if (!point) return null;

  const { y: pointValue, series, point: _point, percentage } = point;
  const { index: pointIndex, suppressed = false } = _point;
  const { name: seriesName } = series;
  const confidenceIntervalIndex = series?.index === 0 ? series?.index : series?.index - 1;
  const confidenceIntervalsForSeries = confidenceIntervals?.[confidenceIntervalIndex];
  const confidenceIntervalsForPoint = confidenceIntervalsForSeries?.[pointIndex] as
    | undefined
    | MhcConfidenceInterval;
  const value = formatValueByUnit({
    value: pointValue ?? 0,
    unit: percent ? "percent" : unit ?? "count",
    precision,
    isPercent: percent ?? false
  });
  const seriesNameText = overrideSeriesName
    ? overrideSeriesName
    : seriesName?.includes("Series ")
    ? ""
    : seriesName;

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <ChartSeriesIcon color={point.color} colorIndex={pointIndex} />
      <Stack direction="row" gap={1.75} alignItems="center">
        <Typography variant="body3" lineHeight={1} display="flex" gap="3px" minWidth={200}>
          {seriesNameText && (
            <Typography component="span" variant="body3" data-label="series name">
              {seriesNameText}:{" "}
            </Typography>
          )}
          {suppressed === false && (
            <Typography fontWeight={700} component="span" variant="body3">
              {percentage && showPercentage
                ? formatValueByUnit({ value: percentage, isPercent: true, precision: 0 })
                : value}
            </Typography>
          )}
          {suppressed === true && (
            <Typography variant="body3" color="textSecondary" component="span">
              In order to protect privacy this value is not shown
            </Typography>
          )}
        </Typography>
        {confidenceIntervalsForPoint && <ConfidenceIntervals {...confidenceIntervalsForPoint} />}
      </Stack>
    </Stack>
  );
};

export default ChartTooltipPoint;
