import { AccessibilityOptions } from "highcharts";

import { brandPalette } from "theme/colors";

const highchartsAccessibilityOptions = (options: AccessibilityOptions = {}) => {
  return {
    enabled: true,
    keyboardNavigation: {
      enabled: true,
      focusBorder: {
        enabled: true,
        hideBrowserFocusOutline: true,
        margin: 2,
        style: {
          color: brandPalette.brand.main,
          lineWidth: 2
        }
      }
    },
    screenReaderSection: {
      axisRangeDateFormat: "%Y-%m-%d"
    },
    series: {
      describeSingleSeries: true
    },
    ...options
  };
};

export default highchartsAccessibilityOptions;
