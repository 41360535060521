import { add } from "date-fns/add";
import { startOfYear } from "date-fns/startOfYear";

import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { getUtcDateFromString } from "common/util/utcDateFromString";

export const months = {
  Jan: "January",
  Feb: "February",
  Mar: "March",
  Apr: "April",
  May: "May",
  Jun: "June",
  Jul: "July",
  Aug: "August",
  Sep: "September",
  Oct: "October",
  Nov: "November",
  Dec: "December"
};

/**
 * This has not been converted to date-fns because the return value is actually a formatting string for Highcharts, not
 * a string that is a formatted date. This makes week formatting impossible, as it is not available in the Highcharts
 * formatting options.
 *
 * @param granularity
 *
 * @returns formatting string for Highcharts
 *
 * @see https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats
 */
export const xDateFormat = (granularity?: MhcTimeSeriesGranularityEnum) => {
  // Need to add support for weekly/week granularity
  if (
    granularity === MhcTimeSeriesGranularityEnum.Year ||
    granularity === MhcTimeSeriesGranularityEnum.FiveYearWindow
  ) {
    return "%Y";
  }
  if (granularity === MhcTimeSeriesGranularityEnum.Month) {
    return "%b %Y";
  }
  return "%m/%d/%Y";
};

type SeriesByYearParams = {
  dates: string[];
  values: (number | null)[];
};
export type SeriesItem = { date: string; value: number | null };
export type SeriesItemDictionary = Record<string | number, SeriesItem[]>;

/**
 * Convert timeSeries into object where dates/values are organized by year and month
 *
 * @param params
 * @param params.dates - array of dates as string ("XXXX-XX-XX")
 * @param params.values - array of values
 *
 * @returns Object where series are by year and month
 *
 * @see SeriesItemDictionary
 *
 */
export const seriesByYearAndMonth = ({ dates, values }: SeriesByYearParams) => {
  return dates.reduce((acc: SeriesItemDictionary, dateString: string) => {
    const date = add(getUtcDateFromString(dateString) as Date, { days: 1 });
    const year: number = date.getFullYear();
    const month: number = date.getMonth();

    acc[year] = acc[year] || [];
    acc[year][month] = { date: dateString, value: values[dates.indexOf(dateString)] ?? null };

    return acc;
  }, {} as SeriesItemDictionary);
};

/**
 * Convert timeSeries into object where dates/values are year
 *
 * @param params
 * @param params.dates - array of dates as string ("XXXX-XX-XX")
 * @param params.values - array of values
 *
 * @returns Object where series are by year and month
 *
 * @see SeriesItemDictionary
 *
 */
export const seriesByYearQuarter = ({
  dates,
  values
}: SeriesByYearParams): SeriesItemDictionary => {
  return dates.reduce((acc: SeriesItemDictionary, dateString: string) => {
    const date = getUtcDateFromString(dateString) as Date;
    const year = startOfYear(date).getFullYear();

    acc[year] ||= [];
    acc[year].push({ date: dateString, value: values[dates.indexOf(dateString)] ?? null });

    return acc;
  }, {});
};
