import { Box, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import { dateFormat } from "highcharts";

import { MhcConfidenceInterval, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { xDateFormat } from "../util/date";
import { TooltipFormatterParams, TooltipPoint } from "../util/tooltip";
import { formatQuarterDate, weekStartAndEndDates } from "common/util/date";
import { formatValueByUnit } from "common/util/formatHelpers";
import { normalizeDate } from "common/util/utcDateFromString";

import ChartSeriesIcon from "./ChartSeriesIcon";
import ChartTooltipPoint from "./ChartTooltipPoint";
import ConfidenceIntervals from "./ConfidenceIntervals";

export type ChartTooltipContentProps = TooltipFormatterParams;

const ChartTooltipContent: React.FC<ChartTooltipContentProps> = ({
  points = [],
  title,
  subtitle,
  statCaption,
  xAxisEnhancements = {},
  granularity,
  confidenceIntervals,
  customDateString,
  ...props
}) => {
  const {
    xCategories,
    categoryName = "",
    overrideXDateFormat,
    overrideXDateFormatter
  } = xAxisEnhancements;
  const point = points[0];
  let pointLabel = point?.series?.name ?? point?.point?.name ?? "";
  if (!point) return null;
  let formattedDate = "";
  let category: null | string = null;
  const categories = xCategories;
  const categoriesReadableDict = xAxisEnhancements?.xCategoriesReadableDict;
  if (category || categories?.length) {
    category = point?.category ?? categories?.[point?.x ?? 0] ?? "";
    category && categoriesReadableDict && (category = categoriesReadableDict[category] ?? "");
    if (category && categoryName) {
      pointLabel = `${categoryName}: ${category}`;
    }
  } else {
    if (overrideXDateFormatter && point?.x) {
      formattedDate = overrideXDateFormatter({
        date: point.x,
        granularity: granularity ?? undefined
      });
    } else if (granularity === MhcTimeSeriesGranularityEnum.Week) {
      formattedDate = weekStartAndEndDates({
        end: normalizeDate(new Date(point.x as number)),
        formatAsString: true
      }) as string;
    } else if (granularity === MhcTimeSeriesGranularityEnum.Quarter) {
      formattedDate = formatQuarterDate(normalizeDate(new Date(point.x as number)), {
        useFullMonthName: true
      });
    } else {
      formattedDate = dateFormat(
        overrideXDateFormat ?? xDateFormat(granularity ?? undefined),
        point?.x ?? 0
      );
    }
  }

  const onlyOnePoint = points.length === 1;
  let pointConfidenceIntervals = null;
  if (onlyOnePoint) {
    const { series: pointSeries, point: pointPoint } = points[0] as TooltipPoint;
    const confidenceIntervalIndex =
      pointSeries?.index === 0 ? pointSeries?.index : pointSeries?.index - 1;
    pointConfidenceIntervals = confidenceIntervals?.[confidenceIntervalIndex]?.[
      pointPoint.index
    ] as MhcConfidenceInterval;
  }

  return (
    <Stack gap={onlyOnePoint ? 0.25 : 0.75}>
      {onlyOnePoint === false && (
        <Stack gap={0.5}>
          {title && (
            <Typography
              variant="body3"
              fontWeight={600}
              lineHeight={1}
              minWidth={title.length > 50 ? 300 : undefined}
              display="block"
            >
              {title}
            </Typography>
          )}
        </Stack>
      )}
      {onlyOnePoint === true && (
        <Stack gap={0.25}>
          <Stack direction="row" alignItems="center" gap={0.75}>
            <ChartSeriesIcon color={point.color} colorIndex={0} />
            <Typography
              variant="body3"
              fontWeight={600}
              lineHeight={1}
              flex="1"
              minWidth={pointLabel.length > 50 ? 300 : undefined}
              display="block"
              sx={{ whiteSpace: pointLabel.length < 50 ? "nowrap" : "normal" }}
            >
              {pointLabel}
            </Typography>
          </Stack>
          <Box>
            {point?.y !== undefined && (
              <Stack direction="row" gap={0.5} alignItems="baseline">
                <Typography variant="body2" fontWeight={700}>
                  {formatValueByUnit({
                    value: point?.y,
                    unit: props.unit,
                    precision: props.precision,
                    isPercent: props.percent
                  })}
                </Typography>
                {statCaption && (
                  <Typography variant="body3" color="#666666" lineHeight={1} whiteSpace="nowrap">
                    {statCaption}
                  </Typography>
                )}
              </Stack>
            )}
          </Box>
        </Stack>
      )}
      {statCaption && points.length > 1 && (
        <Typography variant="body3" color="#666666" lineHeight={1} whiteSpace="nowrap">
          {statCaption}
        </Typography>
      )}
      {isNil(statCaption) && subtitle && onlyOnePoint === false && (
        <Typography variant="caption" color="#666666" lineHeight={1} whiteSpace="nowrap">
          {subtitle}
        </Typography>
      )}
      {formattedDate && !customDateString && (
        <Typography variant="body4" lineHeight={1}>
          {formattedDate}
        </Typography>
      )}
      {category && (
        <Typography variant={"body3"} lineHeight={1}>
          {categoryName && `${categoryName} `}
          {category}
        </Typography>
      )}
      {customDateString && (
        <Typography variant="body4" lineHeight={1} mb={0.25}>
          {customDateString}
        </Typography>
      )}
      {points.length > 1 && (
        <Stack gap={0.5}>
          {points.map((point, i) => (
            <ChartTooltipPoint
              point={point}
              key={`chart-point-${i}-${point.point.index as number}`}
              single={onlyOnePoint}
              confidenceIntervals={confidenceIntervals}
              {...props}
            />
          ))}
        </Stack>
      )}
      {onlyOnePoint === true && pointConfidenceIntervals && (
        <Box mt={0.5}>
          <ConfidenceIntervals {...pointConfidenceIntervals} />
        </Box>
      )}
    </Stack>
  );
};

export default ChartTooltipContent;
