import React from "react";
import ScaleIcon from "@mui/icons-material/Scale";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import { IconProps, Stack } from "@mui/material";

import { MhcConfidenceInterval } from "graphqlApi/types";

import ChartTooltipValueWithIcon from "./ChartTooltipValueIcon";

const ConfidenceIntervals: React.FC<MhcConfidenceInterval & Pick<IconProps, "color" | "sx">> = ({
  upper,
  lower,
  weightedFrequency,
  // Need to style the path of the SVG because when the icon is rendered as HTML, the path is larger
  // than the bounds of the SVG, so it gets clipped.
  sx = { fontSize: 16 },
  color = "action"
}) => {
  return (
    <Stack direction="row" alignItems="center" gap={1.25}>
      <ChartTooltipValueWithIcon
        value={lower}
        icon={<VerticalAlignBottomIcon sx={sx} color={color} className="mui-icon" />}
      />
      <ChartTooltipValueWithIcon
        value={upper}
        icon={<VerticalAlignTopIcon sx={sx} color={color} className="mui-icon" />}
      />
      <ChartTooltipValueWithIcon
        value={weightedFrequency?.toLocaleString() ?? ""}
        icon={<ScaleIcon sx={sx} color={color} className="mui-icon" />}
      />
    </Stack>
  );
};

export default ConfidenceIntervals;
