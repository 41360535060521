type ConfigKey =
  | "average"
  | "bars"
  | "benchmark"
  | "comparison"
  | "moreThanTenPoints"
  | "primary"
  | "secondary"
  | "set"
  | "trend";

type ColorConfigItem = {
  fill: string;
  border: string;
  text: string;
};

export const CHART_COLORS: string[] = [
  "#1E90FF",
  "#E5B303",
  "#85C390",
  "#A0DEE7",
  "#B4AFFF",
  "#F7C390",
  "#296069",
  "#4E1199",
  "#9C6F4C",
  "#FF6584"
];

export const colorConfig: Record<ConfigKey, string | string[] | ColorConfigItem[]> = {
  primary: CHART_COLORS[0] as string,
  secondary: CHART_COLORS[1] as string,
  average: "#b5daff",
  trend: "#888888",
  bars: [
    // We should add these for each color in set...and generate the border/text
    // programmatically with a package or a function
    { fill: CHART_COLORS[0] as string, border: "#007AF5", text: "#70B8FF" },
    { fill: CHART_COLORS[1] as string, border: "#C99E03", text: "#FCD64A" }
  ],
  benchmark: "#CCCCCC",
  comparison: "#BDBDBD",
  set: CHART_COLORS,
  moreThanTenPoints: [
    "#660099",
    "#797765",
    "#80f192",
    "#d771f1",
    "#66a172",
    "#869dbc",
    "#ffbe7d",
    "#fabfd2",
    "#fff0a9",
    "#8cd17d",
    "#e14d6f",
    "#9ccc65",
    "#58a14e",
    "#8e8cb0",
    "#46783c",
    "#d37296",
    "#9fcc65",
    "#8ffae6",
    "#9fa8da",
    "#304ffe",
    "#d399ad",
    "#e3b5f9",
    "#f7bcdf",
    "#4527a0",
    "#9d8577",
    "#827718",
    "#4050b5",
    "#b9aca8",
    "#f89b9b",
    "#86bcb6",
    "#536dfe",
    "#b9b2e8",
    "#74d1a3",
    "#264220",
    "#ffbe7d",
    "#fabfd2",
    "#e35424"
  ]
};

export const defaultChartColorSet = colorConfig.set as string[];
