import { ReactElement } from "react";
import { Stack, Typography } from "@mui/material";

const ChartTooltipValueWithIcon = ({
  value,
  icon
}: {
  value?: string | number;
  icon: ReactElement;
}) => {
  if (!value) return null;
  return (
    <Stack direction="row" gap={0.5} alignItems="center">
      {icon}
      <Typography component="span" variant="body3">
        {value}
      </Typography>
    </Stack>
  );
};

export default ChartTooltipValueWithIcon;
